import { GlobalState } from 'little-state-machine';
export const initialState = {
	calculator: {
		pets: {
			currentStep: 1,
		},
		horses: {
			currentStep: 1,
		},
	},
	nav: {
		isOpen: false,
	},
	currentPage: null,
	currentPetType: null,
};

export default function updateAction(state: any, payload: any): any {
	return {
		...state,
		calculator: {
			...state.calculator,
			[state.currentPage]: {
				...state.calculator[state.currentPage],
				...payload,
			},
		},
	};
}

export function updateCurrentStep(state: any, payload: any): any {
	return {
		...state,
		calculator: {
			...state.calculator,
			[state.currentPage]: {
				...state.calculator[state.currentPage],
				currentStep: payload,
			},
		},
	};
}

export const clearAction = (state: any) => {
	return {
		...state,
		calculator: {
			...state.calculator,
			[state.currentPage]:
				initialState.calculator[
					state.currentPage as keyof typeof initialState.calculator
				],
		},
		nav: initialState.nav,
		currentPetType: null,
	};
};

export const updateNavState = (state: GlobalState, payload: boolean) => {
	return {
		...state,
		nav: {
			isOpen: payload,
		},
		currentPage: null,
		currentPetType: null,
	};
};

export const setCurrentPage = (state: GlobalState, payload: string) => {
	return {
		...state,
		currentPage: payload,
	};
};

export const setCurrentPetType = (state: GlobalState, payload: string) => {
	return {
		...state,
		currentPetType: payload,
	};
};
