import React from 'react';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { initialState } from '~/reducers/updateAction';

export type TAppStore = {
	calculator: {
		[key: string]: any;
	};
	nav: {
		isOpen: boolean;
	};
	currentPage: string;
	currentPetType: string;
};

createStore(initialState);

export const wrapRootElement = ({ element }: any) => {
	return <StateMachineProvider>{element}</StateMachineProvider>;
};
